html {
  box-sizing: border-box;
  background-color: #f5f5fa;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.layout {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100vh;
}

.header,
.footer {
  display: flex;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
}

.body {
  flex-grow: 1;
}

.pageLoading {
  position: absolute;
  left: 50%;
  top: 50%;
}

.landingPage {
  display: flex;
  justify-content: space-evenly;
  /* padding: 20px; */
}

.lpMemePic {
  box-sizing: border-box;
  width: 450px;
  max-width: 100%;
  height: 470px;
  border-radius: 10px;
}

.landingPgBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.lpBtn {
  color: white;
  border-radius: 9px;
  text-align: center;
  padding: 18px;
  margin-top: 20px;
  width: 350px;
  background: linear-gradient(#3d94f6, #1e62d0);
}

.lpBtn:hover {
  background: linear-gradient(#1e62d0, #3d94f6);
  cursor: pointer;
}

.headerComp {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 18px;
  text-align: center;
  font-size: 27px;
}

.headerComp > p {
  font-family: "Pacifico", cursive;
  margin: 0;
  background: linear-gradient(
    to right,
    rgba(11, 89, 158),
    rgba(8, 63, 109),
    rgba(4, 34, 57)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dropdown {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.dropdown > label {
  font-size: 18px;
}

.dropdown > select {
  font-size: 18px;
  background-color: #dbe2ec;
  border-radius: 6px;
}

.meme-page {
  display: flex;
  padding: 20px 60px;
}

.img-div:hover {
  cursor: pointer;
}

.fetchedImages {
  display: block;
  width: 270px;
  height: auto;
  margin: auto;
}

.imageBox {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.text-container > p {
  position: absolute;
  margin: 0;
  top: 20rem;
  left: 40rem;
  -webkit-text-stroke: 1.2px white;
}

.text-container > p:hover {
  border-bottom: 1px dashed black;
  cursor: grab;
}

.Inputs {
  width: 360px;
  margin: 40px;
  /* position: absolute; */
  top: 150px;
  left: 100px;
  margin-top: 10px;
  margin-left: 20px;
  padding: 10px;
}

input::placeholder {
  text-align: center;
}

.font-edit {
  display: flex;
  flex-direction: column;
}

.edit {
  width: 100%;
  margin-bottom: 10px;
  padding: 6px;
  text-align-last: center;
  background-color: white;
  border: 1px solid black;
  border-radius: 6px;
}

.textIndex {
  margin: auto;
  padding: 9px;
  font-size: 18px;
}

.languages,
.btn,
#selectlang {
  margin-bottom: 10px;
  padding: 6px;
  width: 100%;
  text-align: center;
  background-color: white;
  border: 1px solid black;
  border-radius: 6px;
}

.languages {
  height: 30px;
}

#upload-image {
  display: none;
}

label {
  display: block;
  cursor: pointer;
}

.upload-btn {
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
  border: 1px solid black;
  border-radius: 6px;
  padding: 6px;
  color: white;
  font-weight: bold;
  background: linear-gradient(#3d94f6, #1e62d0);
}

.upload-btn:hover {
  background: linear-gradient(#1e62d0, #3d94f6);
  cursor: pointer;
}

.sidebar {
  display: flex;
  flex-direction: column;
  position: fixed;
  height: 100vh;
  width: 180px;
}

a {
  text-decoration: none;
  text-align: right;
  letter-spacing: 6px;
  color: black;
  font-size: 18px;
  font-weight: 600;
  margin: 0.5rem 0rem 0rem 0rem;
  padding: 0.6rem 0rem;
  transition: all 0.3s linear;
}

a.navlink.active {
  text-decoration: none;
  color: #1e62d0;
  border-right: 3px solid #1e62d0;
}

.toggle-btn {
  display: none;
  position: absolute;
  left: 10px;
  top: 25px;
  font-size: 1.2rem;
  border: none;
}

.my-masonry-grid {
  display: -webkit-box;
  /* Not needed if autoprefixing */
  display: -ms-flexbox;
  /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px;
  /* gutter size offset */
  width: auto;
}

.itemsDiv {
  display: flex;
  gap: 3rem;
}

.my-masonry-grid {
  width: 1000px;
  margin: auto;
}

.my-masonry-grid_column {
  padding: 30px;
  /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 30px;
}

.btn-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  /* padding-bottom: 30px; */
}
.page-btn {
  width: 2rem;
  height: 2rem;
  background: #8bcbf9;
  border-color: transparent;
  border-radius: 5px;
  cursor: pointer;
  margin: 0.5rem;
  transition: all 0.3s linear;
}
.active-btn {
  background: #063251;
  color: white;
}
.prev-btn,
.next-btn {
  background: transparent;
  border-color: transparent;
  font-weight: bold;
  text-transform: capitalize;
  letter-spacing: 0.1rem;
  margin: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
}
.pageLoading {
  position: absolute;
  left: 50%;
  top: 50%;
}

.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  position: relative;
  bottom: 0;
}

.footerBtn {
  background: #f5f5fa;
  margin: 0 18px;
  border: none;
}

@media screen and (max-width: 1100px) {
  .meme-page {
    flex-direction: column;
  }
  .meme-container {
    display: inline-flex;
  }
  .landingPage {
    flex-direction: column;
  }
  .landingPgMeme {
    margin: auto;
  }
  .landingPgBtn {
    margin: auto;
  }
  .text-container > p {
    position: absolute;
    margin: 0;
    top: 39rem;
    left: 15rem;
  }
  .my-masonry-grid {
    width: 700px;
  }
}

@media screen and (max-width: 1200px) {
  .toggle-btn {
    display: block;
    font-size: 3.2rem;
  }
  .sidebar {
    background-color: aliceblue;
    width: 100vw;
    align-items: center;
  }
}
